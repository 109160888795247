import React, { useEffect, useState } from "react"
import { RESPONSIVE_STYLES, OWNEDMEDIA_WORDPRESS_URL } from "../AppConst";
import styled from "styled-components";
import { WPFrontMatter } from "../types/WPFrontMatter";
import Layout from "../template/Layout";
import Head from "../head";
import Articles from "../components/blog/Articles";
import { graphql } from "gatsby";
import { BlogPaginator } from "../components/blog/common/paginator";
import { ConnectionFailedText } from "../components/common/ConnectionFailedText";
/**
 *@ Element & Styles
 */
const fetchSearchPosts = async (searchParams, pageNum, setPosts, setTotalPosts, setTotalPages, setIsLoading, setHasError) => {
  setIsLoading(true);
  const pageQuery = pageNum > 0 ? `&page=${pageNum}` : ``;
  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&search=${searchParams}${pageQuery}&per_page=12`)
  .then(response => {
    setTotalPosts(Number(response.headers.get("X-WP-Total")));
    setTotalPages(Number(response.headers.get("X-WP-TotalPages")));
    return response.json();
  })
  .then(postsData => {
    const posts: WPFrontMatter[] = [];

    postsData.map((data, i) => {
      const postData: WPFrontMatter = {
        title: data.title.rendered,
        date: data.date,
        category: data._embedded["wp:term"][0][0].slug,
        description: "",
        author: data._embedded.author[0].name,
        thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
        slug: `${data._embedded.author[0].slug}/${data.slug}`
      }
      posts.push(postData);
    })

    setPosts(posts);
    setIsLoading(false);
  })
  .catch((error) => {
    setHasError(true);
  });
};

const NoResultWrapper = styled.div`
  font-size: 2rem;
  width: 100%;
  text-align: center;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.6rem;
  }
`

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `SEARCH RESULT`, path: `search` },
]

export default function Blog({data}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[] | null>(null);
  const [totalPosts, setTotalPosts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const title = `検索結果の記事一覧 | ネクストライブ株式会社`;
  const description = `ネクストライブ株式会社のブログページです。テクノロジー、クリエイティブ、プログラミング、ビジネス、ワークスタイル、ライフスタイル、エンタメ、お知らせのブログ記事の一覧を掲載しています。`;

  if(typeof window == "undefined") return null;

  const location = window.location.search;
  const searchParam = new URLSearchParams(location).get("query")?.replace(/^\s+/, '').replace(/\s+/g, '+');
  const pageParam = new URLSearchParams(location).get("page")
  const pageNum = pageParam ? Number(pageParam) : 1;

  useEffect(() => {
    fetchSearchPosts(searchParam, pageNum, setPosts, setTotalPosts, setTotalPages, setIsLoading, setHasError)
  }, [location])

  return (
    <Layout sidebar={true} breadcrumbsData={breadcrumbsData}>
      <Head
        title={title}
        description={description}
      />
      { hasError ? <ConnectionFailedText /> :
          totalPosts > 0 ? (
            <>
              <Articles pageTitle={`検索結果`} posts={posts} members={data.site.siteMetadata.member} count={totalPosts} isLoading={isLoading} />
              <BlogPaginator
                basePath={`/search`}
                prev={pageNum > 1 ? `/search?query=${searchParam}&page=${pageNum - 1}` : `/search?query=${searchParam}`}
                next={pageNum < totalPages ? `/search?query=${searchParam}&page=${pageNum + 1}` : `/search?query=${searchParam}&page=${pageNum}`}
                numberOfPages={totalPages}
                current={pageNum > 1 ? pageNum : 1}
                query={searchParam}
              />
            </>
          ) : (
            <NoResultWrapper>
              検索結果がありませんでした。
              <br />
              条件を変更して検索してください。
            </NoResultWrapper>
          )
      }
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        member {
          name
          filename
          pathname
        }
      }
    }
  }
`